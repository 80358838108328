<template>
  <div>
    <b-modal
      id="modal-delete"
      centered
      title="Delete Lead Update File"
      ok-only
      ok-title="Accept"
      @ok="deleteLeadFile"
    >
      <b-form
        @submit.prevent="handleSubmit(deleteLeadFile)"
      >
        <div>Are you sure to delete this file from this event ?</div>
      </b-form>
    </b-modal>

    <div
      v-for="(file,index) in leadDetailLogData.lead_file_logs"
      :key="index"
      class="d-flex justify-content-between align-items-center border-bottom"
    >
      <template>
        <span class="font-weight-bold">
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            variant="flat-dark"
            class="btn-icon p-0"
            @click="downloadLeadFile(file.id, file.file_name)"
          >
            <h6>{{ file.file_name }}</h6>
          </b-button>
        </span>
        <span class="font-weight-bold">
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            v-b-modal.modal-delete
            variant="flat-danger"
            class="btn-icon rounded-circle p-0"
            @click="fileId = file.id"
          >
            <feather-icon
              icon="XIcon"
              size="14"
            />
          </b-button>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import {
  BButton, BForm, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    leadDetailLogData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      fileData: {
        description: '',
        file_object: null,
      },
      lead_files: [],
      disableButtonUpload: false,
      fileId: 0,
    }
  },
  mounted() {
    this.fetchLeadFiles()
  },
  methods: {
    downloadLeadFile(idLeadFile, fileName) {
      const fileNameArr = fileName.split('.')
      const extensionFile = fileNameArr.pop()

      store.dispatch('menu-lead/downloadLeadFile', {
        id: idLeadFile,
        extension: extensionFile,
      })
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteLeadFile() {
      store.dispatch('menu-lead/deleteLeadFile', {
        id: this.fileId,
      })
        .then(() => {
          this.$emit('refetch-data-lead-file')
        })
        .catch(() => {
        })
    },
    async fetchLeadFiles() {
      if (this.$route.params.id !== undefined){
        await store.dispatch('menu-lead/getLeadFiles', { id: this.$route.params.id })
          .then(response => {
            this.lead_files = response.data.data
          })
          .catch(error => {
            if (error.response !== undefined){
              this.$emit('refetch-upload-file', error)
            }
          })
      }
    },
  },
}
</script>

<style scoped>
</style>
