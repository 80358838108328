<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddEventLeadSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-event-lead-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ isEditModal === true ? 'Edit Update' : 'Add Update' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          enctype="multipart/form-data"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row
            class="mb-2"
          >
            <b-col
              md="12"
              class="px-1 mb-1"
            >
              <span>Current Status {{ isEditModal === true ? '' : 'Will Change' }}</span>
            </b-col>
            <b-col
              v-if="isEditModal !== true"
              md="6"
              class="px-1"
            >
              <b-form-group>
                <label for="change-destination-status-label">Change Status To: <span class="text-danger"> *</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Destination Status"
                  vid="destination-status"
                  rules="required"
                >
                  <v-select
                    id="change-status-label"
                    v-model="formData.destination_status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    class="w-100"
                    :clearable="false"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-form-group>
                <label for="lead-description">
                  Description
                  <span>({{ formData.description.length }} / 10000)</span>
                  <span class="text-danger"> *</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  vid="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="lead-description"
                    v-model="formData.description"
                    trim
                    placeholder="Describe event here"
                    rows="4"
                    v-html="isEditModal === true ? leadDetailLogData.description : ''"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-form-group
                label="Deal Price"
                label-for="deal-price"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    Rp
                  </b-input-group-prepend>
                  <cleave
                    id="deal-price"
                    v-model="formData.deal_price_log"
                    class="form-control"
                    :raw="false"
                    :options="currencyFormat"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <div class="font-medium-1">
                Files
              </div>
              <div
                v-if="isEditModal === true"
                class="mt-1 mb-1 font-small-3 text-secondary"
              >
                In this update:
              </div>
              <list-files-component
                v-if="isEditModal === true"
                :lead-detail-log-data="leadDetailLogData"
                @refetch-data-lead-file="refetchDataLead"
              />
              <b-form-group
                class="mt-1"
                label="Upload(s)"
                label-for="attach-file"
              >
                <b-form-file
                  v-model="formData.files"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".png, .jpg, .jpeg, .docx, .doc, .pdf, .xlsx, .xls, .txt, .odt, .ods, .odp"
                />
              </b-form-group>
              <div class="font-small-2 mt-0">
                Max 1.5 MB
              </div>
            </b-col>
            <b-col>
              <b-form-group>
                  <label for="change-status-label">Salesperson: <span class="text-danger"> *</span></label>
                  <validation-provider
                      #default="{ errors }"
                      name="Salesperson"
                      vid="salesperson"
                      rules="required"
                  >
                      <v-select
                          v-model="formData.assigned_salesperson_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="availableSalespersonOptions"
                          class="w-100"
                          :clearable="false"
                          :reduce="val => val.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="disableBtn"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>

      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormTextarea, BButton, BRow, BCol, BInputGroup, BInputGroupPrepend, BFormFile,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, integer, email,
} from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import genders from '@/@fake-db/data/other/genders'
import store from '@/store'
import ListFilesComponent from '@/views/menus/leads/details/components/ListFilesComponent.vue'

export default {
  components: {
    ListFilesComponent,
    vSelect,
    Cleave,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BRow,
    BCol,
    BFormFile,

    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddEventLeadSidebarActive',
    event: 'update:is-add-event-lead-sidebar-active',
  },
  props: {
    isEditModal: {
      type: Boolean,
      required: true,
    },
    isAddEventLeadSidebarActive: {
      type: Boolean,
      required: true,
    },
    leadDetailLogData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    assignedSalesperson: {
      type: Object,
      required: true,
      default: null,
    },
    currentLeadStatus: {
      type: String,
      default: '',
    },
    availableSalespersonOptions: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
    currentStatusId: {
      type: Number,
      default: null,
    },
    currentUser: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      email,
      genders,
      statusOptions: [],
      toast: useToast(),
      formData: {
        description: '',
        lead_detail_log_id: 0,
        is_update_log: false,
        deal_price_log: null,
        destination_status: null,
        assigned_salesperson_id: null,
        files: null,
      },
      currencyFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      disableBtn: false,
    }
  },
  watch: {
    leadDetailLogData() {
      this.formData.description = this.leadDetailLogData.description
      this.formData.deal_price_log = this.leadDetailLogData.deal_price_log
      this.formData.lead_detail_log_id = this.leadDetailLogData.id
    },
    assignedSalesperson() {
      if (this.assignedSalesperson != null) {
        this.formData.assigned_salesperson_id = this.assignedSalesperson?.id
      }
    },
    isEditModal(newVal) {
      if (newVal === true){
        this.formData.description = this.leadDetailLogData.description
        this.formData.deal_price_log = this.leadDetailLogData.deal_price_log
        this.formData.lead_detail_log_id = this.leadDetailLogData.id
        this.formData.is_update_log = newVal
      } else {
        this.formData.description = ''
        this.formData.is_update_log = newVal
        this.formData.lead_detail_log_id = 0
        this.formData.deal_price_log = null
      }
    },
  },
  created(){
    this.fetchAllLeadStatuses()
  },
  methods: {
    async fetchAllLeadStatuses() {
      await store.dispatch('menu-lead/getWithoutAllLeadStatuses')
        .then(response => {
          Object.entries(response.data.data).forEach(value => {
            if (value[1].id !== response.data.data[0].id){
              this.statusOptions.push({ label: value[1].status_name, value: value[1].id })
            }
          })
        })
    },
    refetchDataLead() {
      this.$emit('refetch-data')
      this.$emit('update:is-add-event-lead-sidebar-active', false)
    },
    async onSubmit() {
      this.disableBtn = true
      this.formData.current_user_id = this.currentUser.id
      const newFormData = new FormData()

      if (this.formData.deal_price_log !== null && this.formData.deal_price_log !== ''){
        this.formData.deal_price_log = parseInt(this.formData.deal_price_log.split(',').join(''), 10)
      } else {
        this.formData.deal_price_log = null
      }

      newFormData.append('deal_price_log', this.formData.deal_price_log)
      newFormData.append('current_user_id', this.formData.current_user_id)
      newFormData.append('destination_status', this.formData.destination_status)
      newFormData.append('description', this.formData.description)
      newFormData.append('lead_detail_log_id', this.formData.lead_detail_log_id)
      newFormData.append('assigned_salesperson_id', this.formData.assigned_salesperson_id)
      newFormData.append('is_update_log', this.formData.is_update_log)
      newFormData.append('files', this.formData.files)
      // for (const i of Object.keys(this.formData.files)) {
      //   newFormData.append('files', this.formData.files[i])
      // }

      store.dispatch('menu-lead/updateLeadStatusDetail', {
        form_input_id: this.$route.params.id,
        data: newFormData,
      })
        .then(response => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-event-lead-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Successfull!',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.formData.description = ''
          this.formData.deal_price_log = null
          this.formData.files = null
          this.disableBtn = false
        })
        .catch(error => {
          if (error.response !== undefined){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${error.response.status} Error has occured`,
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.disableBtn = false
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
