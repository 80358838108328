<template>
  <div>
    <b-card
      no-body
      :class="{'open': visible}"
      style="border-bottom: none !important"
      @mouseenter="collapseOpen"
      @mouseleave="collapseClose"
    >
      <b-card-header
        :class="{'collapsed': !visible}"
        class="px-0 py-0 w-100"
        :aria-expanded="visible ? 'true' : 'false'"
        :aria-controls="collapseItemID"
        role="tab"
        data-toggle="collapse"
        @click="updateVisible(!visible)"
      >
        <slot name="header">
          <div class="lead collapse-title w-100">
            <b-row class="px-1 text-small">
              <b-col
                cols="12"
                sm="5"
                md="1"
                lg="2"
                xl="3"
                class="pt-1 px-0 collapse-create"
              >
                <b-badge
                  variant="light-dark"
                  class="badge-text"
                >
                  <p v-if="isSystem === true">
                    System
                  </p>
                  <p v-else>
                    {{ by | truncate(4, '...') }}
                  </p>
                </b-badge>
              </b-col>
              <b-col
                cols="12"
                sm="5"
                md="4"
                lg="4"
                xl="3"
                class="pt-2 px-0 collapse-time text-right"
              >
                <h6>
                  {{ time }}
                </h6>
              </b-col>
              <b-col
                cols="12"
                sm="1"
                md="6"
                lg="5"
                class="d-inline-block pt-2 pr-lg-0 collapse-desc text-right"
              >
                <h6>
                  {{ title }}
                </h6>
              </b-col>
            </b-row>
          </div>
        </slot>
      </b-card-header>

      <b-collapse
        :id="collapseItemID"
        v-model="visible"
        :accordion="accordion"
        role="tabpanel"
        class="row"
      >
        <b-card-body
          class="pl-md-1 pl-xs-0"
          cols="12"
        >
          <slot />
        </b-card-body>
      </b-collapse>
    </b-card>
    <hr>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BRow,
    BCol,
    BBadge,
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) { return text.substring(0, length) + suffix }
      return text
    },
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isSystem: {
      type: Boolean,
      default: false,
    },
    by: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
