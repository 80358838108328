<template>
  <div>
    <div
      v-for="(value, key) in formName"
      :key="key"
      ref="row"
      class="mb-1"
    >
      <div>
        <h5 class="font-weight-bold">
          <b>{{ formLabel[key] }}</b>:
        </h5>
        <span
          class="font-weight-bold"
          v-html="formContent(formData[value])"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    formLabel: {
      type: Array,
      default: () => {
        return []
      },
    },
    formName: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
    }
  },
  methods: {
    formContent(items) {
      let form = ''
      if (items) {
        form = typeof items === 'object' ? items.join(', ') : items
      } else {
        form = '-'
      }
      return form
    },
  },
}
</script>

<style scoped>
</style>
