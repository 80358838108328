<template>
  <div>
    <add-event-lead
      :current-user="currentUser"
      :lead-detail-log-data="leadLogEdited"
      :current-lead-status="leadDetail.currentStatusLead"
      :current-status-id="leadDetail.currentStatusId"
      :available-salesperson-options="optionSalesperson"
      :is-edit-modal="isEditModal"
      :assigned-salesperson="salesperson"
      :is-add-event-lead-sidebar-active.sync="isAddEventLead"
      @refetch-data="refetchData"
    />
    <!-- faq search section -->
    <section id="header">
      <div
        v-if="false"
        style="background-color: #edefed"
        class="rounded d-flex flex-column flex-md-row justify-content-between mb-3 p-1"
      >
        <div class="vertical-center py-0">
          <h6>
            Current Status: <span class="font-weight-bolder">{{ leadDetail.currentStatusLead }} ({{ lead_last_updated | moment("from", "now") }})</span>
          </h6>
        </div>
        <div class="vertical-center d-flex flex-column flex-md-row">
          <div class="mr-md-1">
            <b-form-checkbox
              v-model="leadDetail.hotlead"
              :disabled="hotleadDisabled"
              :value="true"
              @change="changeHotleadColumn"
            >
              Hotlead
            </b-form-checkbox>
          </div>
          <div>
            <b-form-checkbox
              v-if="currentUser.role.code === 'su'"
              v-model="leadDetail.reshuffleSalesperson"
              :disabled="reshuffleSalespersonDisabled"
              :value="true"
              @change="changeReshuffleSalespersonStatus"
            >
              Exclude this lead from salesperson reshuffle
            </b-form-checkbox>
          </div>
        </div>
      </div>

      <!-- Change Salesperson Modal -->
      <b-modal
        id="modal-select2-salesperson"
        title="Change Salesperson"
        ok-title="confirm"
        ok-only
        centered
        @ok="submitSalesperson"
      >
        <b-form @submit.prevent="handleSubmit(submitSalesperson)">
          <b-form-group
            label="Choose one"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="selectedSalesperson"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionSalesperson"
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <!-- Change Description Log -->
      <!--      <b-modal-->
      <!--        id="modal-change-description"-->
      <!--        title="Change Description"-->
      <!--        ok-title="confirm"-->
      <!--        ok-only-->
      <!--        no-close-on-backdrop-->
      <!--        centered-->
      <!--        @ok="submitEditDescription"-->
      <!--      >-->
      <!--        <b-form @submit.prevent="handleSubmit(submitEditDescription)">-->
      <!--          <b-form-group-->
      <!--            label="Text here"-->
      <!--            label-for="change-description"-->
      <!--          >-->
      <!--            <b-form-textarea-->
      <!--              id="change-description"-->
      <!--              v-model="leadLog.description"-->
      <!--              trim-->
      <!--              rows="3"-->
      <!--            />-->
      <!--          </b-form-group>-->
      <!--        </b-form>-->
      <!--      </b-modal>-->
    </section>

    <section id="form-detail">
      <div class="mb-2">
        <h2>{{ landingPageTitle }}</h2>
      </div>
      <b-row
        v-if="leadDetailFlag === true && lead_details_log_flag === true && lead_files_flag === true"
        class="d-flex justify-content-between px-0"
      >
        <b-col lg="4">
          <b-row>
            <!-- Form Content -->
            <b-col
              cols="12"
              md="6"
              lg="12"
            >
              <div>
                <b-card>
                  <b-card-body class="justify-content-start p-0">
                    <div class="d-flex justify-content-between">
                      <div class="mr-md-1 mb-2">
                        <b-form-checkbox
                          v-model="leadDetail.hotlead"
                          :disabled="hotleadDisabled"
                          :value="true"
                          @change="changeHotleadColumn"
                        >
                          Hotlead
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-button
                          pill
                          variant="outline-dark"
                          size="sm"
                          @click="deleteEntry"
                        >
                          <feather-icon icon="Trash2Icon" />
                          Delete
                        </b-button>
                      </div>
                    </div>
                    <form-content-component
                      :form-data="form_data"
                      :form-label="form_label"
                      :form-name="form_name"
                    />
                  </b-card-body>
                </b-card>
              </div>
            </b-col>

            <!-- Files -->
            <b-col
              cols="12"
              md="6"
              lg="12"
            >
              <div>
                <b-card title="Files">
                  <b-card-body class="p-0 m-0">
                    <app-collapse
                      v-if="lead_files.length > 0"
                      accordion
                    >
                      <template
                        v-for="(item, index) in lead_files"
                      >
                        <app-collapse-item
                          :key="index"
                          :title="item.created_at | moment('H:mm | D MMM YYYY')"
                        >
                          <b-row class="d-flex flex-column flex-md-row justify-content-between px-0">
                            <b-col class="text-muted">
                              <h6>{{ item.created_by.name }}</h6>
                            </b-col>
                            <b-col class="text-right">
                              <b-button
                                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                variant="flat-primary"
                                class="btn-icon rounded-circle p-0"
                                @click="downloadLeadFile(item.id, item.file_name)"
                              >
                                <feather-icon icon="FileIcon" />
                                <span class="font-small-3">{{ item.file_name }}</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </app-collapse-item>
                      </template>
                    </app-collapse>
                  </b-card-body>
                </b-card>
              </div>
            </b-col>

          </b-row>
        </b-col>
        <b-col lg="8">
          <b-card>
            <b-card-body class="px-0 mx-0">
              <div
                class="d-flex justify-content-between text-primary ml-1"
              >
                <div>
                  <h4>Updates</h4>
                </div>
                <div>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    pill
                    size="sm"
                    @click="() => { isAddEventLead = true; isEditModal = false }"
                  >
                    Add
                  </b-button>
                </div>
              </div>

              <b-card>
                <b-card-body class="px-0 pt-0">
                  <app-collapse
                    accordion
                    class="mt-1"
                  >
                    <template
                      v-for="(item, index) in lead_details_log"
                    >
                      <div
                        v-if="item.description === null"
                        :key="index"
                      >
                        <b-row
                          class="text-small"
                        >
                          <b-col
                            cols="12"
                            sm="5"
                            md="1"
                            lg="2"
                            xl="3"
                            class="pt-1 pr-0 update-create"
                          >
                            <b-badge
                              variant="light-dark"
                              class="badge-text"
                            >
                              <p
                                v-if="item.by_system === true"
                              >
                                System
                              </p>
                              <p v-else>
                                {{ item.created_by === null ? '' :item.created_by.name | truncate(4, '...') }}
                              </p>
                            </b-badge>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="5"
                            md="4"
                            lg="4"
                            xl="3"
                            class="pt-2 pr-0 update-time text-right"
                          >
                            <h6>
                              {{ item.created_at | moment('h:mm a | D MMM YYYY') }}
                            </h6>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="1"
                            md="6"
                            lg="5"
                            class="d-inline-block pt-2 pl-lg-0 update-desc text-right"
                          >
                            <h6>
                              {{ item.title }}
                            </h6>
                          </b-col>
                        </b-row>
                        <hr>
                      </div>
                      <app-collapse-lead-item
                        v-else
                        :key="index"
                        :is-system="item.by_system"
                        :by="item.created_by === null ? '' :item.created_by.name"
                        :time="item.created_at | moment('h:mm a | D MMM YYYY')"
                        :title="item.title"
                      >
                        <div
                          style="background-color: #edefed"
                          class="rounded d-flex flex-column justify-content-start pt-1 pr-1 pb-1"
                        >
                          <div class="text-right">
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="flat-info"
                              size="sm"
                              class="p-0"
                              @click="editDescription(item)"
                            >
                              <span class="text-primary">Edit</span>
                            </b-button>
                          </div>
                          <div class="pl-1">
                            {{ item.description }}
                          </div>
                          <div class="pl-1 pt-1">
                            <div
                              v-for="(file,index) in item.lead_file_logs"
                              :key="index"
                              class="d-flex justify-content-between align-items-center border-bottom"
                            >
                              <span class="font-weight-bold">
                                <b-button
                                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                  variant="flat-dark"
                                  class="btn-icon p-0"
                                  @click="downloadLeadFile(file.id, file.file_name)"
                                >
                                  <feather-icon
                                    icon="FileIcon"
                                  />
                                  <span class="text-primary">{{ file.file_name }}</span>
                                </b-button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </app-collapse-lead-item>
                    </template>
                  </app-collapse>
                </b-card-body>
              </b-card>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <div
        v-else
        class="text-center"
      >
        <span>Loading...</span>
        <div class="d-flex justify-content-center mb-1">
          <b-spinner label="Loading..." />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import store from '@/store'
/* eslint-enable */
import {
  BCard, BCardBody, BRow, BCol, VBModal, BModal, BForm, BFormGroup, BFormCheckbox, BButton, BBadge, BSpinner, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseLeadItem from '@core/components/app-collapse/AppCollapseLeadItem.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AddEventLead from './AddEventLead.vue'
import FormContentComponent from './FormContentComponent.vue'

export default {
  components: {
    AddEventLead,
    vSelect,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BModal,
    BBadge,
    BForm,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BSpinner,

    FormContentComponent,
    AppCollapse,
    AppCollapseItem,
    AppCollapseLeadItem,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    Ripple,
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) { return text.substring(0, length) + suffix }
      return text
    },
  },
  data() {
    return {
      user_id: null,
      statusesLead: [],
      optionSalesperson: [],
      isAddEventLead: false,
      leadDetailFlag: false,
      landingPageLabel: '',
      landingPageTitle: '',
      leadDetail: {
        id: null,
        currentStatusLead: '',
        currentStatusId: null,
        nextStatusLead: '',
        reshuffleSalesperson: false,
        hotlead: false,
        marketing_channel: '',
        isFailStatus: false,
        isDealStatus: false,
      },
      reshuffleSalespersonDisabled: false,
      hotleadDisabled: false,
      landingPageData: {
        picture_link: null,
        description: null,
        title: null,
        form_id: null,
      },
      lead_details_log: [],
      lead_details_log_flag: false,
      form_data: null,
      form_label: [],
      form_name: [],
      lead_last_updated: '',
      salesperson: null,
      formSalespersonData: {
        salesperson_id: null,
        form_input_id: null,
      },
      selectedSalesperson: '',
      currentUser: {
        role: {
          code: '',
        },
      },
      // change description
      leadLogEdited: {},
      lead_files: [],
      lead_files_flag: false,
      isEditModal: false,
    }
  },
  created() {
    this.refetchData()
    this.getCurrentUser()
    this.getSelf()
  },
  methods: {
    getSelf() {
      store.dispatch('app-user/getSelf')
        .then(response => {
          this.user_id = response.data.id
        })
    },
    deleteEntry() {
      this.$swal({
        title: 'Delete entry',
        text: 'Pemanently delete this entry?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('menu-lead/deleteLeads', {
            leads_id: [this.leadDetail.id],
            user_id: this.user_id,
          })
            .then(response => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'leads-landing-page', params: { uuid: this.$route.params.uuid } })
            }).catch(error => {
              if (error.response !== undefined){
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error has occured',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
                this.importBtn = false
              }
            })
        }
      })
    },
    async fetchData() {
      if (this.$route.params.id !== undefined){
        this.reshuffleSalespersonDisabled = true
        this.hotleadDisabled = true
        this.leadDetailFlag = false
        await store.dispatch('menu-lead/getDetailFormInput', { id: this.$route.params.id })
          .then(response => {
            // resolve Landing Page data
            this.lead_last_updated = response.data.data.updated_at
            this.salesperson = response.data.data.salesperson === undefined ? null : response.data.data.salesperson
            this.leadDetail.id = response.data.data.id
            this.leadDetail.currentStatusLead = response.data.data.current_status_name
            this.leadDetail.currentStatusId = response.data.data.current_status_id
            this.leadDetail.nextStatusLead = response.data.data.next_status
            this.leadDetail.isFailStatus = response.data.data.is_fail_status
            this.leadDetail.isDealStatus = response.data.data.is_deal_status
            this.leadDetail.reshuffleSalesperson = response.data.data.reshuffle_status
            this.leadDetail.hotlead = response.data.data.hot_lead
            this.leadDetail.marketing_channel = response.data.data.marketing_channel
            this.landingPageLabel = response.data.data.landing_page_label
            this.landingPageTitle = response.data.data.landing_page_title
            this.form_data = JSON.parse(response.data.data.form_data)
            this.form_label = JSON.parse(response.data.data.form_labels)
            this.form_name = JSON.parse(response.data.data.form_names)

            this.leadDetailFlag = true
            this.reshuffleSalespersonDisabled = false
            this.hotleadDisabled = false
          })
          .catch(error => {
            if (error.response !== undefined){
              this.getErrorToast(error)
            }
          })
      }
    },
    async fetchLeadFiles() {
      if (this.$route.params.id !== undefined){
        this.lead_files_flag = false
        await store.dispatch('menu-lead/getLeadFiles', { id: this.$route.params.id })
          .then(response => {
            this.lead_files = response.data.data
            this.lead_files_flag = true
          })
          .catch(error => {
            if (error.response !== undefined){
              this.$emit('refetch-upload-file', error)
            }
          })
      }
    },
    async fetchLeadDetailsLog() {
      if (this.$route.params.id !== undefined){
        this.lead_details_log_flag = false
        await store.dispatch('menu-lead/getLeadDetailLog', { id: this.$route.params.id })
          .then(response => {
            // resolve Landing Page data
            this.lead_details_log = response.data.data
            this.lead_details_log_flag = true
          })
          .catch(error => {
            if (error.response !== undefined){
              this.getErrorToast(error)
            }
          })
      }
    },
    async getAvailableSalespersons() {
      if (this.$route.params.id !== undefined){
        await store.dispatch('menu-lead/allSalespersonAssignedByLandingPage', { uuid: this.$route.params.uuid })
          .then(response => {
            this.optionSalesperson = response.data.data
          })
      }
    },
    async getCurrentUser() {
      await store.dispatch('app-user/getSelf')
        .then(response => {
          this.currentUser = response.data
        })
    },
    downloadLeadFile(idLeadFile, fileName) {
      const fileNameArr = fileName.split('.')
      const extensionFile = fileNameArr.pop()

      store.dispatch('menu-lead/downloadLeadFile', {
        id: idLeadFile,
        fileName: fileNameArr[0],
        extension: extensionFile,
      })
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    refetchData(){
      this.fetchData()
      this.fetchLeadFiles()
      this.fetchLeadDetailsLog()
      this.getAvailableSalespersons()
    },
    refetchUploadFile(result){
      if (result.response === undefined){
        this.getSuccessToast(result)
      } else {
        this.getErrorToast(result)
      }
    },
    handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.submitSalesperson()
    },
    submitSalesperson() {
      this.formSalespersonData.salesperson_id = this.selectedSalesperson.value
      this.formSalespersonData.form_input_id = this.$route.params.id
      this.formSalespersonData.current_user_id = this.currentUser.id
      store.dispatch('menu-lead/assignSalesperson', this.formSalespersonData)
        .then(response => {
          this.getSuccessToast(response)
          this.refetchData()
        })
        .catch(error => {
          if (error.response !== undefined){
            this.getErrorToast(error)
          }
        })
    },
    editDescription(leadLogEdited) {
      this.leadLogEdited = leadLogEdited
      this.isEditModal = true
      this.isAddEventLead = true
    },
    async changeReshuffleSalespersonStatus() {
      this.reshuffleSalespersonDisabled = true
      await store.dispatch('menu-lead/updateLeadReshuffleStatus', {
        form_input_id: this.$route.params.id,
        data: { reshuffle_status: this.leadDetail.reshuffleSalesperson },
      })
        .then(response => {
          this.getSuccessToast(response)
        })
        .catch(error => {
          if (error.response !== undefined){
            this.getErrorToast(error)
          }
        })
      this.reshuffleSalespersonDisabled = false
    },
    async changeHotleadColumn() {
      this.hotleadDisabled = true
      await store.dispatch('menu-lead/updateHotlead', {
        form_input_id: this.$route.params.id,
        data: { hot_lead: this.leadDetail.hotlead },
      })
        .then(response => {
          this.getSuccessToast(response)
        })
        .catch(error => {
          if (error.response !== undefined){
            this.getErrorToast(error)
          }
        })
      this.hotleadDisabled = false
    },
    getErrorToast(error){
      return this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `${error.response.status} Error has occured`,
          icon: 'XIcon',
          variant: 'danger',
          text: error.response.data.message,
        },
      })
    },
    getSuccessToast(response){
      return this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success!',
          icon: 'CheckIcon',
          variant: 'success',
          text: response.data.message,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
